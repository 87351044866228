<template>
  <div style="padding: 0 40px 0">
    <div class="message">
      <div class="left">
        <div class="top">
          <Input
            v-model="userSearch"
            suffix="ios-search"
            type="text"
            @on-enter="searchUserMethod"
            style="
              margin-top: 20px;
              height: 30px;
              padding: 0 10px;
            "
          />
          <input type="text" class="elHidden">
        </div>
        <div class="userList subject" ref="userList">
          <div
            @click="getUserLetter(item)"
            v-for="item in list"
            :key="item.id"
            class="box"
          >
            <div class="boxLeft" v-if="item.headPhoto">
              <img v-if="item.bType === 2" :src="$global.imgUrl + item.headPhoto" alt="" />
              <img  v-else :src="$global.imgUrl2 + item.headPhoto" alt="" />
            </div>
            <div class="boxLeft" v-else>
              <img src="@/assets/img/userInfo/user.png" />
            </div>
            <div class="boxRight">
              <div class="name">
                <span class="se">{{ item.name }}</span>
                <span>{{ item.message.sendTime.slice(5,16) }}</span>
              </div>
              <div class="content">{{ item.message.content }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="top">
          <div class="name">
            {{ msgList.userInfo.name }}
          </div>
        </div>
        <div ref="box" class="middle subject">
          <div class="look" @click="upGetData" v-show="historyList">
            查看更多历史消息
          </div>
          <div
            style="width: 90%; min-height: 100px;"
            :style="item.businessId !== userInfo.user.id ? 'float: left;' : 'float: right'"
            v-for="item in msgList.userLetterList"
            :key="item.id"
          >
            <div v-if="item.businessId !== userInfo.user.id" class="bmsg">
              <div class="photo" v-if="item.businessCover">
                <img
                  v-if="item.businessType === 2"
                  :src="$global.imgUrl + item.businessCover"
                  alt=""
                />
                <img
                  v-else
                  :src="$global.imgUrl2 + item.businessCover"
                  alt=""
                />
              </div>
              <div v-else class="photo">
                <img src="@/assets/img/userInfo/user.png" />
              </div>
              <span class="bmsgContent">{{ item.content }}</span>
              <div class="jiao"></div>
            </div>
            <div v-if="item.businessId === userInfo.user.id" class="mymsg">
              <span class="mymsgContent">{{ item.content }}</span>
              <div class="jiao"></div>

              <div class="photo" v-if="item.businessCover">
                <img
                  v-if="msgList.userInfo.businessType === 2"
                  :src="$global.imgUrl + item.businessCover"
                  alt=""
                />
                <img
                  v-else
                  :src="$global.imgUrl + item.businessCover"
                  alt=""
                />
              </div>
              <div v-else class="photo">
                <img src="@/assets/img/userInfo/user.png" />
              </div>
            </div>
          </div>
        </div>
        <div class="input">
          <div v-show="canSend" class="shadow"></div>
          <textarea
            maxlength="500"
            placeholder="请输入"
            v-model="output"
            @keydown="enterkeyboard($event)"
          ></textarea>
          <div class="tips">
            <div class="size">{{ output.length }}/500</div>
            <div class="operation">
              按下Enter发送内容/ Ctrl+Enter换行
              <span
                @click="sendMsg"
                style="
                  width: 110px;
                  display: inline-block;
                  height: 30px;
                  color: #ffffff;
                  margin-left: 10px;
                  line-height: 30px;
                  text-align: center;
                  background: #00a4ff;
                  border-radius: 10px;
                  cursor: pointer;
                "
                >发送</span
              >
            </div>
          </div>
          <Spin fix v-show="sendSpin"></Spin>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getLetterList,
  senMessage,
  userMsgList,
  SpecifiedList
} from "../../../../../apis/user";
import { mapState } from "vuex";

export default {
  name: "message",
  computed: {
    ...mapState(["userInfo"]),
    canSend() {
      return this.msgList.userInfo.bType === 6
    },
  },
  data() {
    return {
      userSearch: null,
      list: [], // 私信人员列表数组
      userPage: 1, // 私信人列表页码
      hasUserNextPage: false, // 私信人列表是否有下一页
      output: "",
      userListPage: null, // 私信聊天框页码
      historyList: false, // 私信聊天框是否有历史消息
      msgList: {
        userInfo: {},
        userLetterList: [], // 私信聊天框聊天列表
      },
      sendSpin: false,
      sendLoding: false
    };
  },
  created() {
    if (this.$route.params.name) {
      this.getSpecifiedList()
      this.getList(this.$route.params.id);
    } else {
      this.getList(false,true);
    }
  },
  mounted() {
    this.handleScroll()
  },
  methods: {
    // 获取当前用户与指定用户会话
    async getSpecifiedList() {
      let obj = {
        anotherId: this.$route.params.id,
        anotherType: this.$route.params.type
      }
      const resp = await SpecifiedList(obj)
      if (!resp.data) {
        let obj = {
          headPhoto: this.$route.params.foto,
          name: this.$route.params.name,
          bType: this.$route.params.type,
          bid: this.$route.params.id,
          message: {
            sendTime: "",
            content: "",
          }
        }
        this.list.unshift(obj)
        this.msgList.userInfo = obj;
      } else {
        this.list.unshift(resp.data)
        this.getUserLetter(this.list[0])
      }
    },
    // 搜索私信用户
    searchUserMethod() {
      this.userPage = 1
      this.getList(false,true)
    },
    // 监听私信人员列表滚动条
    handleScroll() {
      let _dom = this.$refs.userList
      _dom.onscroll = () => {
        if (_dom.scrollTop + _dom.offsetHeight === _dom.scrollHeight && this.hasUserNextPage) {
          this.userPage += 1
          this.getList()
        }
      }
    },
    // 跳转聊天框到最下面
    add() {
      let div = this.$refs.box;
      //此时必须异步执行滚动条滑动至底部
      setTimeout(() => {
        div.scrollTop = div.scrollHeight;
      }, 0);
    },
    // 按下Enter发送内容/ Ctrl+Enter换行
    enterkeyboard(event) {
      if (event.keyCode == 13) {
        if (event.ctrlKey) {
          this.output = this.output + '\n';
        } else {
          event.preventDefault();
          this.sendMsg();
        }
      }
    },
    // 发送消息
    async sendMsg() {
      if (this.canSend || this.sendLoding) return
      if (!this.output) {
        this.sendLoding = true
        setTimeout(() => {
          this.sendLoding = false
        }, 2000);
        this.$Message.warning("消息不能为空");
        return
      }
      this.sendSpin = true
      let obj = {
        content: this.output,
        userId: this.msgList.userInfo.bid,
        userType: this.msgList.userInfo.bType
      };
      await senMessage(obj);
      this.output = "";
      this.userPage = 1
      let _dom = this.$refs.userList
      _dom.scrollTop = 0
      await this.getList(false,true);
      this.add();
      // this.sendSpin = false
    },
    // 点击查看历史信息
    upGetData() {
      this.userListPage -= 1;
      this.getUserLetter(null,true);
    },
    // 获取私信用户列表
    async getList(id,isSend=false) {
      let obj = {
        ignoreAnotherId: id ? id : null,
        ignoreAnotherType: id ? this.$route.params.type : null,
        name: this.userSearch,
        pageNo: this.userPage,
        pageSize: 8,
      };
      const { data } = await userMsgList(obj);
      this.hasUserNextPage = data.hasNextPage
      if (isSend) {
        this.list = [...data.list]
        if (this.list.length > 0) {
          this.getUserLetter(this.list[0])
        }
      } else {
        this.list.push(...data.list)
      }
    },
    // 获取私信用户聊天内容
    async getUserLetter(item,isNext=false) {
      this.output = ""
      this.sendSpin = true
      if (item) this.msgList.userInfo = item;
      let obj = {
        anotherId: this.msgList.userInfo.bid,
        anotherType: this.msgList.userInfo.bType,
        pageNo: isNext ? this.userListPage : null,
        pageSize: 10,
      };
      let { data } = await getLetterList(obj);
      isNext ? this.msgList.userLetterList.unshift(...data.list) : this.msgList.userLetterList = [...data.list]
      this.historyList = data.hasPreviousPage;
      this.userListPage = data.pageNum;
      if(!isNext) this.add();
      this.sendSpin = false
    },


    setTime(arr) {
      console.log(arr);
      let date = `${this.MyGetYear(arr[0].sendTime)}${this.myGetMonth(
        arr[0].sendTime
      )}${this.myGetDay(arr[0].sendTime)}`;
      console.log(date);
      let newDate = false;
      arr.map((item) => {
        item["isTime"] = false;
        if (!newDate) {
          newDate = `${this.MyGetYear(item.sendTime)}${this.myGetMonth(
            item.sendTime
          )}${this.myGetDay(item.sendTime)}`;
          item["time"] = newDate;
          item.isTime = true;
        } else {
          let ItemDate = `${this.MyGetYear(item.sendTime)}${this.myGetMonth(
            item.sendTime
          )}${this.myGetDay(item.sendTime)}`;
          if (ItemDate !== newDate) {
            newDate = ItemDate;
            item["time"] = newDate;
            item.isTime = true;
          }
        }
      });
    },
    MyGetYear(time) {
      return new Date(time).getFullYear();
    },
    myGetMonth(time) {
      let month = new Date(time).getMonth() + 1;
      month < 10 ? (month = `0${month}`) : month;
      return month;
    },
    myGetDay(time) {
      let day = new Date(time).getDate();
      return day < 10 ? (day = `0${day}`) : day;
    },
    setData(arr, type) {
      if (arr.length > 3000) {
        console.log(1);
        if (type === "up") {
          //从后面删除
        } else {
          //从前面删除
        }
      }
      console.log(type);
    },
  },
};
</script>

<style lang="scss" scoped>
.subject::-webkit-scrollbar {
  width: 4px;
}

.subject::-webkit-scrollbar-thumb {
  width: 4px;
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #00a4ff;
}

.subject::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  background: #d3d3d3;
}

.subject {
  overflow-y: scroll;
}
.message {
  height: 750px;
  background: #ffffff;
  border: 1px dotted #999999;
  border-radius: 6px;
  display: flex;

  .left {
    border-right: 1px dotted #999;
    //background: yellow;
    .top {
      height: 70px;
      border-bottom: 1px dotted #999;
      ::v-deep .ivu-input-suffix {
        text-align: left;
      }
      .elHidden {
        width:0;
        opacity:0;
      }
    }

    .userList {
      height: 680px;
      .box:hover {
        border-top: 1px dotted #00a4ff;
        border-bottom: 1px dotted #00a4ff;
      }

      .box:hover .se {
        color: #00a4ff !important;
      }
      .box:hover .content {
        color: #00a4ff !important;
      }
      .box:hover .boxLeft span {
        color: #00a4ff !important;
        border: 1px solid #00a4ff !important;
        //border-style: #00A4FF;
      }
      .box:hover .boxLeft img {
        border: 1px solid #00a4ff !important;
        //border-style: #00A4FF;
      }
      .box {
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 0 5px;
        height: 105px;
        border-top: 1px dotted #d6d6d6;
        border-bottom: 1px dotted #d6d6d6;
        cursor: pointer;

        .boxRight {
          height: 65px;
          padding: 0 5px;
          //background: yellow;
          .content {
            font-size: 14px;
            font-family: Source Han Sans SC;
            font-weight: 400;
            color: #999999;
            line-height: 20px;
            width: 265px;
            display: -webkit-box;
            word-wrap: break-word;
            -webkit-line-clamp: 2; // 设置显示行数
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
          .name {
            display: flex;
            justify-content: space-between;

            span:nth-child(1) {
              width: 170px;
              font-size: 18px;
              font-family: Source Han Sans SC;
              font-weight: 400;
              color: #333333;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              cursor: pointer;
            }

            span:nth-child(2) {
              font-size: 14px;
              font-family: Source Han Sans SC;
              font-weight: 400;
              color: #999999;
            }
          }
        }

        .boxLeft {
          width: 65px;
          height: 65px;
          border-radius: 50%;

          img {
            width: 65px;
            height: 65px;
            background: #f6f6f6;
            // border: 1px solid #999999;
            border-radius: 50%;
          }
        }
      }
    }
  }

  .right {
    width: 100%;
    //background: pink;
    .top {
      height: 70px;
      border-bottom: 1px dotted #999;
      //background: gray;
      .name {
        margin-left: 25px;
        line-height: 70px;
        font-size: 24px;
        font-family: Source Han Sans SC;
        font-weight: 400;
      }
    }

    .middle {
      padding-bottom: 10px;
      padding-top: 10px;
      width: 100%;
      height: 479px;
      border-bottom: 1px dotted #999;
      .photo {
        img {
          width: 65px;
          height: 65px;
          background: #f6f6f6;
          // border: 1px solid #999999;
          border-radius: 50%;
        }
      }
      .look {
        width: 100%;
        height: 50px;
        cursor: pointer;
        text-align: center;
        font-family: Source Han Sans SC;
        font-weight: 400;
        color: #00a4ff;
      }
      .bmsg {
        display: flex;
        white-space: pre-wrap;
        margin-top: 30px;
        margin-left: 25px;
        position: relative;
        .bmsgContent {
          padding: 16px 20px 16px 24px;
          margin-left: 10px;
          max-width: 500px;
          line-height: 24px;
          background: #f9f9f9;
          border-radius: 10px;
          font-size: 16px;
          font-family: Source Han Sans SC;
          font-weight: 400;
          color: #333333;
        }
        .jiao {
          width: 0;
          left: 64px;
          top: 20px;
          height: 0;
          position: absolute;
          border-top: 20px solid #f9f9f9;
          //border-top: 20px solid rebeccapurple;
          border-left: 20px solid transparent;
        }
      }
      .mymsg {
        white-space: pre-wrap;
        display: flex;
        margin-right: 25px;
        margin-top: 30px;
        //margin-left: 210px;
        float: right;
        position: relative;
        .mymsgContent {
          font-size: 16px;
          font-family: Source Han Sans SC;
          font-weight: 400;
          color: #333333;
          line-height: 24px;
          margin-right: 50px;
          max-width: 713px;
          background: #e3f5ff;
          border-radius: 10px;
          //background: yellow;
          //padding-top: 16px;
          padding: 16px 20px 16px 24px;
        }
        .jiao {
          width: 0;
          right: 97px;
          top: 20px;
          height: 0;
          position: absolute;
          //border-top: 20px solid red;
          border-top: 20px solid #e3f5ff;
          border-right: 20px solid transparent;
        }
      }
    }
    .input {
      position: relative;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      textarea {
        outline: none;
        width: 99%;
        height: 150px;
        margin-top: 10px;
        resize: none;
        border: none;
      }
      .tips {
        display: flex;
        justify-content: space-between;
        width: 99%;
        line-height: 30px;
        height: 30px;
        font-size: 14px;
        font-family: Source Han Sans SC;
        font-weight: 400;
        color: #999999;
      }
      .shadow {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 8;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.61);
      }
    }
  }
}
</style>
