<template>
  <div id="letter">
    <TopList :navList="navList" @setType="setType" :type="type"></TopList>
    <Message></Message>
  </div>
</template>

<script>
import TopList from "../../components/TopList";
import Message from "./components/message";
export default {
  name: "index",
  components: {
    TopList,
    Message,
  },
  methods: {
    setType(e) {
      this.type = e;
      this.name = this.navList[e];
    },
  },
  data() {
    return {
      type: 0,
      name: "",
      navList: ["全部私信"],
    };
  },
};
</script>

<style lang="scss" scoped>
#letter {
  height: 903px;
  background: #ffffff;
  border: 1px solid #999999;
  border-radius: 10px;
}
</style>
